import React from 'react';
import './Body.scss';
import imgProfil from '../../../images/lamedunpirate.png';
import Plans from '../Plans/Plans';
import FORMULES from '../../../data/formules';
{/* TODO : A activer quand il y aura les photos avant / après */}
// import Clients from '../Clients/Clients';
// import CLIENTS_EVOLUTIONS from '../../../data/evolution';

const Body = () => (
  <>
    <section className="hero is-white is-medium" id="quisuisje">
      <div className="hero-body">
        <div className="container">
          <h1 
            className="title has-text-primary is-with-bar is-family-hakio"
            data-aos="fade-in"
          >
            Qui suis je ?
          </h1>
          <div className="content" data-aos="fade-in" data-aos-delay="100">
            <div className="columns">
              <div className="column is-two-thirds">
                <p className="is-size-4">
                  <strong>
                    Salut à vous, moi, c'est{' '}
                    <span className="has-text-primary">Fabien</span>.
                  </strong>
                </p>
                <p className="is-size-5">
                  Coach en développement sportif diplômé d'état avec{' '}
                  <strong className="has-text-primary">
                    double mention A & B
                  </strong>{' '}
                  (Musculation et cours collectifs).
                </p>
                <p className="is-size-5">
                  Ancien caporal en Opération Artillerie au 11ème rama, j’aime
                  être challengé et c'est ce qui m’a orienté vers{' '}
                  <span className="has-text-primary has-text-weight-semibold	">
                    l'haltérophilie et le crossfit
                  </span>{' '}
                  en tant que{' '}
                  <span className="has-text-primary has-text-weight-semibold	">pratiquant et coach</span>.
                </p>
                <p className="is-size-5">
                  J'ai toujours été passionné par le sport aussi bien individuel
                  que collectif. À l'âge de 4 ans on m'inscrit au judo, ce qui
                  me permet de découvrir des valeurs telles que la
                  <span className="has-text-primary has-text-weight-semibold	">
                    discipline et la rigueur
                  </span>{' '}
                  mais surtout de{' '}
                  <span className="has-text-primary has-text-weight-semibold	">
                    prendre goût au challenge et à la compétition
                  </span>
                  .
                </p>
                <p className="is-size-5">
                  Suite à ça, je pratique le basket pendant 8 ans, puis décide,
                  en 2014, de m'engager dans l'armée.
                </p>
                <p className="is-size-5">
                  J'intègre donc le 11ème rama en tant qu'opérateur d'artillerie
                  dans l'armée de terre. J'y découvre alors les entraînements de
                  type fonctionnel et en ai fait ma spécialité.
                </p>
                <p className="is-size-5">
                  Après 3 ans, je quitte l'armée et me spécialise dans le sport
                  avec une formation de BPJEPS et une formation de coach en
                  haltérophilie.
                </p>
              </div>
              <div className="column is-hidden-touch">
                <img src={imgProfil} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="hero is-large parallax objectif has-text-white" id="objectif">
      <div className="hero-body">
        <div className="container">
          <h1
            className="title has-text-white is-with-bar is-family-hakio"
            data-aos="fade-in"
          >
            Mon objectif
          </h1>
          <div className="content" data-aos="fade-in" data-aos-delay="100">
            <p className="is-size-5">
              Montrer que <span className="has-text-info">tout le monde</span>{' '}
              est capable, quelque soit le point de départ et transmettre mon
              savoir en poussant mes pratiquants hors de leurs limites, tout en
              restant bien entendu dans le{' '}
              <span className="has-text-info">plaisir</span> et la
              <span className="has-text-info">bienveillance</span>.
            </p>
            <p className="is-size-5">
              Adepte de mouvement fonctionnel, de cross training ou d'haltéro ?
            </p>
            <p className="is-size-5">
              Je serai là pour te{' '}
              <span className="has-text-info">préparer</span> et te guider pas à
              pas dans tes <span className="has-text-info">objectifs</span> !
            </p>
          </div>
        </div>
      </div>
    </section>

    {/* TODO : A activer quand il y aura les photos avant / après */}
    {/* <Clients clients={CLIENTS_EVOLUTIONS} /> */}

    <Plans plans={FORMULES} />
  </>
);

export default Body;
