const FORMULES = [
  {
    title: 'Coaching individuel',
    price: 60,
    options: [
      {
        name: '1h de coaching individuel',
      },
    ],
  },
  {
    title: 'Coaching duo',
    price: 90,
    options: [
      {
        name: '1h de coaching en duo',
      },
    ],
  },
  {
    title: 'Shishibukai',
    price: 45,
    options: [
      {
        name: 'Programme prise de masse',
      },
      {
        excluded: true,
        name: 'Suivi et modification de la programmation',
      },
      {
        excluded: true,
        name: 'Analyse complète des habitudes d’entrainement et des besoins nécessaires pour atteindre tes objectifs',
      },
      {
        excluded: true,
        name: "Suivi via mail ou WhatsApp pour t'aider au quotidien",
      },
    ],
  },
  {
    title: 'Amazon lily',
    price: 45,
    options: [
      {
        name: 'Programmes axé booty et abdominaux',
      },
      {
        excluded: true,
        name: 'Suivi et modification de la programmation',
      },
      {
        excluded: true,
        name: 'Analyse complète des habitudes d’entrainement et des besoins nécessaires pour atteindre tes objectifs',
      },
      {
        excluded: true,
        name: "Suivi via mail ou WhatsApp pour t'aider au quotidien",
      },
    ],
  },
  {
    title: 'Nakama',
    price: 60,
    options: [
      {
        name: 'Programme personnalisé',
      },
      {
        name: 'Suivi et modification de la programmation',
      },
      {
        excluded: true,
        name: 'Analyse complète des habitudes d’entrainement et des besoins nécessaires pour atteindre tes objectifs',
      },
      {
        excluded: true,
        name: "Suivi via mail ou WhatsApp pour t'aider au quotidien",
      },
    ],
  },
  {
    title: 'Yonkō',
    price: 75,
    options: [
      {
        name: 'Programme personnalisé',
      },
      {
        name: 'Suivi et modification de la programmation',
      },
      {
        name: 'Analyse complète des habitudes d’entrainement et des besoins nécessaires pour atteindre tes objectifs',
      },
      {
        name: "Suivi via mail ou WhatsApp pour t'aider au quotidien",
      },
    ],
  },
];

export default FORMULES;