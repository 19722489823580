import React from 'react';
import PropTypes from 'prop-types';

const Plans = ({ plans }) => (
  <section className="hero is-medium is-light">
    <div className="hero-body">
      <div className="container">
        <h1
          className="title has-text-primary is-with-bar is-family-hakio"
          data-aos="fade-in"
        >
          Mes formules
        </h1>
        <p className="subtitle" data-aos="fade-in">
          Voici les différentes formules que je propose
        </p>
        <div className="columns is-multiline mt-5" data-aos="fade-in" data-aos-delay="100">
          {
            plans.map((plan) => (
              <div key={plan.title} className="column has-text-centered is-half-desktop">
                <div className="plan box has-background-dark has-text-white is-centered">
                  <h1 className="title is-size-4 has-text-white">
                    {plan.title}
                  </h1>
                  <p className="is-size-3 is-family-secondary">
                    {plan.price}€
                  </p>
                  <div className="formules-separator mx-auto has-background-info"></div>
                  <ul className="list-unstyled my-5 text-small text-left">
                    {
                      plan.options.map((option, index) => (
                        <li key={`${option.name}_${index}`} className="mb-3">
                          <i className={`fa fa-${option.excluded ? 'times' : 'check'} mr-2 text-primary`}></i>
                          {option.excluded ? <del>{option.name}</del> : option.name}
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  </section>
);

Plans.propTypes = {
  plans: PropTypes.array.isRequired,
};

Plans.defaultProps = {
  plans: [],
}

export default Plans;