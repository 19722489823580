import '../scss/app.scss';
import * as React from 'react';
import Body from './components/Body/Body';
import Header from './components/Header/Header';
import Layout from './components/Layout/Layout';

const IndexPage = () => (
  <Layout>
    <Header />
    <Body />
  </Layout>
);

export default IndexPage;
