import React from 'react';
import './Header.scss';

const LOGOS = [
  {
    logo: 'youtube',
    url: 'https://www.youtube.com/channel/UCa7RjyqG2BrlCFBQ5m6MC9g',
    alt: 'Youtube',
  },
  {
    logo: 'instagram',
    url: 'https://www.instagram.com/lamedunpirate',
    alt: 'Instagram',
  },
];

const Header = () => (
  <section className="hero is-transparent parallax home is-fullheight-with-navbar">
    <div className="hero-body">
      <div className="container is-fluid has-text-right">
        <h1 
          className="title is-size-1 is-size-3-touch has-text-dark is-family-hakio"
          data-aos="fade-left"
        >
          Fabien Nanga
        </h1>
        <h2 
          className="subtitle is-size-4 is-size-6-touch is-family-secondary"
          data-aos="fade-left"
          data-aos-delay="400"
        >
          Coach Diplômé d’État
        </h2>
      </div>
    </div>
    <div className="social is-fixed" data-aos="slide-up">
      <ul>
        {
          LOGOS.map(social => 
            <li key={social.logo}>
              <a href={social.url} target="_blank" rel="noreferrer" alt={social?.alt}>
                <i className={`fab fa-${social.logo} fa-2x`}></i>
              </a>
            </li>
          )
        }
      </ul>
    </div>
  </section>
);

export default Header;